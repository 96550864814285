<!--
 * @Description: 登录注册
 * @Author: zhang zhen
 * @Date: 2023-02-05 14:48:41
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-19 17:54:08
 * @FilePath: /page-sass/src/views/login.vue
-->

<template>
  <div class="login-content">
    <div class="login-header">
      <!--      <img src="~@/assets/mainLogo.png" alt="" class="logo">-->
    </div>
    <div class="login-content-left"></div>
    <div class="login-content-right">
      <div class="login-box">
        <img src="~@/assets/login/logo_color.png" alt="" class="center-logo">
        <template v-if="type == 'login'">
          <a-tabs v-model="activeKey" @change="handleSwitchTab" class="loginTab">
            <a-tab-pane key="1" tab="短信登录/注册"><!-- 快捷登录-->
              <div class="login-box-content">
                <span class="login-tag">*未注册手机号验证后将自动创建账号,登录即代表同意</span>
                <a-form-model ref="loginByPhone" :model="form" :rules="loginPhoneRule"
                              @keyup.enter.native="handleLoginByPhone" style="padding-top: 16px">
                  <a-form-model-item prop="mobile" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.mobile">
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>

                  <a-form-model-item prop="captcha" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box" style="margin-top: 0">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha" style="width: 100%"></a-input>
                        <div class="block-line"></div>
                        <a-button class="inp-txt" type="link" :loading="sendCodeSec > 0" @click="handleLoginSendMsg">{{
                            sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s`
                          }}
                        </a-button>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <!--                  <div class="inp-right-txt">-->
                  <!--                    &lt;!&ndash;                    <span @click="handleChangeTab('register')">免费注册</span>&ndash;&gt;-->
                  <!--                    <span style="color: #999;">还没有账号？</span>-->
                  <!--                    <span @click="handleChangeTab('register')">注册一个</span>-->
                  <!--                  </div>-->

                  <a-button type="primary" htmlType="submit" block class="login-btn mb-15"
                            @keydown.enter="handleLoginByPhone" @click="handleLoginByPhone" :loading="loading">登录
                  </a-button>
                  <div class="line16"></div>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                    <div class="police">
                      登录/注册即代表同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和
                      <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="密码登录" class="right-tab">
              <div class="login-box-content">
                <a-form-model ref="loginByUsername" :model="form" :rules="loginByUsernameRule"
                              @keyup.enter.native="handleLoginByUsername" style="box-sizing: border-box">
                  <a-form-model-item prop="username" class="mb-16">
                    <a-input placeholder="请输入手机号" v-model="form.username" />
                  </a-form-model-item>
                  <a-form-model-item prop="password" class="mb-16">
                    <a-input-password placeholder="请输入密码" v-model="form.password" :maxLength="20" />
                  </a-form-model-item>
                  <div class="inp-right-txt">
                    <!--                    <span @click="handleChangeTab('register')">免费注册</span>-->
                    <!--                    <span style="padding: 0 10px"></span>-->
                    <span @click="handleChangeTab('forgetPassword')"
                          style="color: #999;">忘记密码？</span>
                  </div>
                  <a-button class="login-btn mb-15" htmlType="submit" type="primary" block
                            @keydown.enter="handleLoginByUsername" @click="handleLoginByUsername"
                            :loading="loading">登录
                  </a-button>
                  <div class="line16"></div>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                    <div class="police">
                      登录/注册即代表同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和
                      <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
          <!-- 登录区域 -->

        </template>

        <template v-else-if="type == 'register'">
          <div class="memo-header">
            <span class="title">用户注册</span>
            <div class="loginBtn">
              已有账号，
              <a-button type="link" @click="handleChangeTab('login')" style="color: #FF6026">立即登录</a-button>
            </div>
          </div>
          <a-tabs v-model="activeKey" @change="handleSwitchTab" class="register-tabs">
            <a-tab-pane key="1" tab="短信注册">
              <div class="login-box-content">
                <a-form-model ref="registerPhone" :model="form" :rules="registerPhoneRule" :label-col="{ span: 7 }"
                              :wrapper-col="{ span: 17 }" labelAlign="left" @keyup.enter.native="handleRegisterByPhone">
                  <a-form-model-item prop="username" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input placeholder="请输入账号" v-model="form.username" />
                  </a-form-model-item>
                  <a-form-model-item prop="mobile" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.mobile" allowClear>
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="captcha" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box" style="margin-top: 0">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha"></a-input>
                        <div class="block-line"></div>
                        <a-button type="link" class="inp-txt" :loading="sendCodeSec > 0" @click="handleShowCaptcha">{{
                            sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s`
                          }}
                        </a-button>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <a-form-model-item prop="newPassword" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }"
                                     class="mb-16">
                    <a-input-password placeholder="密码为8-16位字母+数字" v-model="form.newPassword" :maxLength="20" />
                  </a-form-model-item>
                  <a-form-model-item prop="pwd" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <a-input-password placeholder="再次输入密码" v-model="form.pwd" :maxLength="20" />
                  </a-form-model-item>
                  <a-form-model-item prop="roleType" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" class="mb-16">
                    <div class="need-desc compact-input">
                      <span class="desc">用户类型</span>
                      <a-select v-model="form.roleType" placeholder="请选择用户类型" @change="handleSwitchUserType">
                        <a-icon type="caret-down" slot="suffixIcon" style="color: #8c8c8c" />
                        <a-select-option :value="undefined">请选择用户类型</a-select-option>
                        <a-select-option v-for="(item, key) in userTypList" :key="item.label" :value="item.value">
                          {{ item.label }}
                        </a-select-option>
                      </a-select>
                    </div>
                    <!-- <a-radio-group v-model="form.roleType" :options="userTypList" @change="handleSwitchUserType" /> -->
                  </a-form-model-item>
                  <a-form-model-item prop="tradeIdentity" :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }"
                                     class="mb-16">
                    <div class="need-desc compact-input">
                      <span class="desc">用户角色</span>
                      <a-select v-model="form.tradeIdentity" @change="handleChangeIdentity"
                                placeholder="请选择用户角色">
                        <a-icon type="caret-down" slot="suffixIcon" style="color: #8c8c8c" />
                        <a-select-option :value="undefined">请选择用户角色</a-select-option>
                        <a-select-option v-for="(item, key) in tradeIdentityList[form.roleType]" :key="item.label"
                                         :value="item.value">
                          <a-tooltip placement="bottomLeft">
                            <template slot="title">
                              <span>{{ item.desc }}</span>
                            </template>
                            <span class="inline-text">{{ item.label }}</span>
                          </a-tooltip>

                        </a-select-option>
                      </a-select>
                    </div>
                  </a-form-model-item>
                  <a-button type="primary" class="login-btn" block style="margin: 0 0 15px"
                            @click="handleRegisterByPhone" :loading="loading">注册
                  </a-button>
                  <a-button type="primary" class="login-btn" block style="margin: 0 0 15px"
                            @click="handleRegister" :loading="loading">选择主体
                  </a-button>

                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" style="margin-top: 8px;">
                    <div class="police">
                      <a-checkbox v-model="form.isRead"></a-checkbox>
                      我已阅读并同意<a href="/userAgreement.html"
                                       target="_blank" class="link">《用户协议》</a> 和 <a href="/userAgreement.html"
                                                                                         target="_blank"
                                                                                         class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-tab-pane>
          </a-tabs>
        </template>

        <template v-else>
          <div class="memo-header" style="justify-content: normal;padding-left: 40px;padding-bottom: 40px">
            <img src="@/assets/return.png" @click="handleChangeTab('login')">
            <span class="title" style="padding-left: 90px;">重置密码</span>
            <!--            <div class="loginBtn">-->
            <!--              已有账号，-->
            <!--              <a-button type="link" @click="handleChangeTab('login')" style="color: #FF6026">立即登录</a-button>-->
            <!--            </div>-->
          </div>
          <a-tabs v-model="activeKey" class="resetPassword" @change="handleSwitchTab">
            <a-tab-pane key="1" tab="短信重置密码">
              <div class="login-box-content" style="padding-top: 0">
                <a-form-model ref="restByPhone" :model="form" :rules="resetPhoneRule">
                  <a-form-model-item prop="phone" class="mb-16">
                    <a-input class="need-desc" placeholder="请输入手机号" v-model="form.phone" allowClear>
                      <template slot="prefix">
                        <span class="desc">中国+86</span>
                      </template>
                    </a-input>
                  </a-form-model-item>
                  <a-form-model-item prop="captcha" class="mb-16">
                    <a-input-group compact>
                      <div class="inp-box">
                        <a-input placeholder="请输入短信验证码" v-model="form.captcha"></a-input>
                        <div class="block-line"></div>
                        <p class="inp-txt" :loading="sendCodeSec > 0" @click="handleSendRestMessage">
                          {{ sendCodeSec == 0 ? '获取验证码' : `${sendCodeSec}s` }}
                        </p>
                      </div>
                    </a-input-group>
                  </a-form-model-item>
                  <a-form-model-item prop="password" class="mb-16">
                    <a-input-password placeholder="密码为8-16位字母+数字" v-model="form.password" />
                  </a-form-model-item>
                  <a-form-model-item prop="confirmPassword" class="mb-16">
                    <a-input-password placeholder="请再次输入密码" v-model="form.confirmPassword" />
                  </a-form-model-item>
                  <div class="loginBtn" style="line-height: 22px;height: 22px !important;">
                    <span style="color: #666666"> 想起密码?</span>
                    <a-button type="link" @click="handleChangeTab('login')" style="color: #FF6026">立即登录</a-button>
                  </div>
                  <a-button type="primary" class="login-btn" style="margin: 40px 0 15px" block
                            @click="handleResetPasswordByPhone" :loading="loading">确认修改
                  </a-button>
                  <div class="line16"></div>
                  <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }" style="">
                    <div class="police">
                      登录/注册即代表同意<a href="/userAgreement.html" target="_blank" class="link">《用户协议》</a> 和
                      <a
                        href="/userAgreement.html" target="_blank" class="link">《隐私政策》</a>
                    </div>
                  </a-form-model-item>
                </a-form-model>

              </div>
            </a-tab-pane>
          </a-tabs>
        </template>
      </div>
    </div>

    <!-- 验证码 -->
    <Captcha ref="Captcha" @ok="handleSendMessage" style="z-index: 9999" />
    <!--选择主体类型弹窗-->
    <a-modal :visible="subjectTypeVisible" :width="880" :footer="null" :closable="true" @cancel="onModalClose">
      <div class="subject" style="padding-left: 64px; padding-right: 64px;">
        <div class="subject-top" style="display: flex;justify-content: flex-end">
          <span class="subject-revert">
<!--            <img src="../assets/to_revert.png" style="width: 24px;height: 24px;margin-right: 8px">-->
            <!--            返回上一步-->
          </span>
          <span class="subject_title" style="padding-right: 240px;">
            <img src="../assets/subject_hand.png" style="width: 40px;height: 40px;margin-right: 8px">
            请选择主体类型</span>
          <span class="subject-card-labe">1/3</span>
        </div>
        <div class="subject-desc">主体类型一旦选择，认证后无法修改，请选择符合要求的主体类型
        </div>
        <div class="cards-box">
          <div class="card-box ">
            <span><img src="../assets/person2.png" class="card-img"></span>

            <span class="card-identity">个人身份</span>
            <span class="card-desc">适合无营业执照、以个人身份入驻</span>
            <a-button type="primary" block style="width: 144px"
                      @click="onPerson">立即入驻
            </a-button>
          </div>
          <div class="card-box " style="margin-left: 32px">
            <span><img src="../assets/company2.png" class="card-img"></span>

            <span class="card-identity">企业身份</span>
            <span class="card-desc">适合有营业执照，以企业/公司身份入驻</span>
            <a-button type="primary" block style="width: 144px"
                      @click="onCompanyInfo">立即入驻
            </a-button>
          </div>
        </div>

      </div>
    </a-modal>
    <!--完善企业信息弹窗-->
    <a-modal :visible="companyInfoVisible" :width="640" :footer="null" :closable="true" @cancel="onModalClose">
      <div class="subject" style="width: 640px;min-height: 702px;padding-bottom: 40px;">
        <div class="subject-top">
          <span class="subject-revert" @click="onReturnStep" style="cursor:pointer;">
            <img src="../assets/to_revert.png" style="width: 24px;height: 24px;margin-right: 8px">
            返回上一步
          </span>
          <span class="subject_title">
            完善企业信息</span>
          <span class="subject-card-labe">3/3</span>
        </div>
        <div class="card-form-box" style="padding-top: 40px;justify-content: center;">
          <a-form-model
            class="form-cover"
            ref="companyInfo"
            :model="companyInfo"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :colon="false"
          >
            <a-row class="basicInfo-form-item">
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item
                  label="公司名称"
                  prop="name"
                  :rules="{ required: true, message: '请输入公司名称' }"
                >
                  <a-input
                    style="width: 320px; height: 38px"
                    placeholder="请输入"
                    v-model="companyInfo.name"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item label="所属集团">
                  <a-input
                    placeholder="请输入"
                    v-model="companyInfo.companyGroup"
                    style="width: 320px; height: 38px"
                  ></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item
                  class="spec-item uploadImg"
                  style="display: flex"
                  label="营业执照"
                  prop="licensePicture"
                  :rules="{ required: true, validator: uploadFileChange }"
                >
                  <uploadFile
                    :number="1"
                    v-model="licensePictureList"
                    :showUploadList="false"
                    :uploadFileType="'image'"
                    :defaultParams="{
                    dir: 'picture',
                  }"
                    :needMemo="false"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item
                  label="所属行业"
                  :rules="{ required: true, message: '请选择所属行业' }"
                  prop="industry"
                >
                  <j-dictSelect
                    style="width: 320px;"
                    v-model="companyInfo.industry"
                    dictCode="1047"
                    placeholder="请选择"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item label="公司类别">
                  <j-dictSelect
                    style="width: 320px; height: 38px"
                    v-model="companyInfo.companyType"
                    dictCode="1045"
                    placeholder="请选择"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 24px"
                     v-if="checkedDesigner || checkedFacilitator || checkedSupplier">
                <a-form-model-item label="发票开具类型" prop="invoiceType"
                                   :rules="{ required: true, message: '请选择' }"
                >
                  <j-dictSelect
                    style="width: 320px; height: 38px"
                    v-model="companyInfo.invoiceType"
                    dictCode="1541"
                    placeholder="请选择"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 24px"
                     v-if="checkedDesigner || checkedFacilitator || checkedSupplier">
                <a-form-model-item label="发票税率" prop="invoiceRate"
                                   :rules="{ required: true, message: '请选择' }"
                >
                  <a-select style="width: 320px;" placeholder="请选择" v-model="companyInfo.invoiceRate">
                    <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">

                    <a-select-option value="0.13">13%</a-select-option>
                    <a-select-option value="0.17">17%</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item label="公司地址" prop="region">
                  <a-cascader allow-clear="false" :options="region" v-model="companyInfo.region" placeholder="请选择"
                              style="width: 320px;"
                              @change="onRegionChange">
                    <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon" style="top:30%">
                  </a-cascader>
                </a-form-model-item>
              </a-col>

              <a-col :span="24" style="margin-top: 16px">
                <a-form-model-item label=" " prop="address">
                  <AddressPicker
                    style="width: 320px"
                    ref="MapPickerRef"
                    map-key="f49ff6740fe5c943d85f87bc114146e0"
                    :disabled="form.status === '0'"
                    v-model="companyInfo.address"
                    @done="onChoose"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="26" class="basicInfo-form-item">
              <a-col :span="24" style="display: flex;justify-content: center">
                <a-button :loading="loading" type="primary" block style="width: 122px;margin-top: 28px"
                          @click="onResidentIdentity">完成
                </a-button>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-modal>

    <!--完善个人信息-->
    <a-modal :visible="personInfoVisible" :width="640" :footer="null" :closable="true" @cancel="onModalClose">
      <div class="subject" style="width: 640px;padding-bottom: 40px;">
        <div class="subject-top">
          <span class="subject-revert" @click="onReturnStep" style="cursor:pointer;">
            <img src="../assets/to_revert.png" style="width: 24px;height: 24px;margin-right: 8px">
            返回上一步
          </span>
          <span class="subject_title">
            完善个人信息</span>
          <span class="subject-card-labe">3/3</span>
        </div>
        <div class="card-form-box" style="padding-top: 40px;justify-content: center;">
          <a-form-model
            class="form-cover"
            ref="personInfo"
            :model="personInfo"
            :colon="false"
          >
            <div style="margin-bottom: 24px;padding-left: 55px;display: flex">
              <a-form-model-item
                label="个人姓名"
                prop="name"
                :rules="{ required: true, message: '请输入个人姓名' }"
                style="display: flex"
              >
                <a-input
                  style="width: 320px; height: 38px"
                  placeholder="个人姓名"
                  v-model="personInfo.name"
                ></a-input>
              </a-form-model-item>
            </div>
            <div style="padding-left: 69px;padding-bottom: 56px;display: flex">
              <a-form-model-item
                label="手机号"
                prop="phone"
                :rules="{ required: true,validator: validatePhone }"
                style="display: flex"
              >
                <a-input
                  style="width: 320px; height: 38px"
                  placeholder="手机号"
                  v-model="personInfo.phone"
                ></a-input>
              </a-form-model-item>
            </div>
            <div style="display: flex;justify-content: center;align-items: center">
              <a-button type="primary" block style="width: 122px;"
                        @click="onPersonResidentIdentity">完成
              </a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <!--选择入驻身份-->
    <a-modal :visible="residentIdentityVisible" :width="880" :footer="null" :closable="true" @cancel="onModalClose">
      <div class="subject" style="padding-left: 64px; padding-right: 64px;">
        <div class="subject-top" style="display: flex;justify-content: space-between">
          <span class="subject-revert" style="cursor:pointer;" @click="onRoleBack">
            <img src="../assets/to_revert.png" style="width: 24px;height: 24px;margin-right: 8px">
                        返回上一步
          </span>
          <span class="subject_title">
            <img src="../assets/subject_hand.png" style="width: 40px;height: 40px;margin-right: 8px">
            请选择您的入驻身份</span>
          <span class="subject-card-labe">2/3</span>
        </div>
        <div class="subject-desc">选择对应的入驻身份，将更好适配符合该身份的工作台
        </div>
        <div class="cards-box-group" style="justify-content: unset;margin-top: 40px">
          <div class="card-box-top">
            <span class="resident-title">入驻身份</span>
            <span class="resident-tag">可多选</span>
            <a-checkbox :indeterminate="indeterminate" style="color:#131212;" :checked="checkAll"
                        @change="onCheckAllChange">
              不限角色
            </a-checkbox>
          </div>
          <a-checkbox-group v-model="checkedRoleList" @change="onRoleChange">
            <div class="card-info-box" style="display: flex;margin-top: 16px">

              <div class="card-box-purchaser">
                <div
                  class="card-box-role-top">
                  <div style="display: flex">
                    <img src="../assets/purchaser2.png" class="card-box-img">
                    <div style="display: flex;flex-direction: column;padding-left: 8px">
                      <span class="card-box-role">采购商</span>
                      <span class="card-box-sign">我有包装采购或设计需求</span>
                    </div>
                  </div>
                  <a-checkbox value="2">
                  </a-checkbox>
                </div>
                <div>
                  <a-radio-group name="radioGroup" v-model="defaultRole" @change="onRoleDeFault"
                                 :disabled="!checkedPurchaser" v-if="checkedRoleList.length>1">
                    <a-radio value="2" checked>设为默认身份</a-radio>
                  </a-radio-group>
                </div>
              </div>
              <div class="card-box-supplier" style="margin-left: 16px">
                <div
                  class="card-box-role-top">
                  <div style="display: flex">
                    <img src="../assets/supplier2.png" class="card-box-img">
                    <div style="display: flex;flex-direction: column;padding-left: 8px">
                      <span class="card-box-role">生产商</span>
                      <span class="card-box-sign">我是厂家源头，提供包装制造、订购</span>
                    </div>
                  </div>
                  <a-checkbox value="0" :disabled="businessType ==='PERSON'">
                  </a-checkbox>
                </div>
                <div>
                  <a-radio-group name="radioGroup" v-model="defaultRole" @change="onRoleDeFault"
                                 v-if="checkedRoleList.length>1"
                                 :disabled="!checkedSupplier">
                    <a-radio value="0">设为默认身份</a-radio>
                  </a-radio-group>
                </div>
              </div>
            </div>
            <div class="card-info-box" style="display: flex;margin-top: 16px">
              <div class="card-box-purchaser">
                <div
                  class="card-box-role-top">
                  <div style="display: flex">
                    <img src="../assets/servicer2.png" class="card-box-img">
                    <div style="display: flex;flex-direction: column;padding-left: 8px">
                      <span class="card-box-role">服务商</span>
                      <span class="card-box-sign">我提供测试、打样、组装等专项服务</span>
                    </div>
                  </div>
                  <a-checkbox value="1" :disabled="businessType ==='PERSON'">
                  </a-checkbox>
                </div>
                <div>
                  <a-radio-group name="radioGroup" v-model="defaultRole" @change="onRoleDeFault"
                                 v-if="checkedRoleList.length>1"
                                 :disabled="!checkedFacilitator">
                    <a-radio value="1">设为默认身份</a-radio>
                  </a-radio-group>
                </div>
              </div>
              <div class="card-box-supplier" style="margin-left: 16px">
                <div
                  class="card-box-role-top">
                  <div style="display: flex">
                    <img src="../assets/designer2.png" class="card-box-img">
                    <div style="display: flex;flex-direction: column;padding-left: 8px">
                      <span class="card-box-role">设计师</span>
                      <span class="card-box-sign">我是设计师，提供包装设计服务</span>
                    </div>
                  </div>
                  <a-checkbox value="4">
                  </a-checkbox>
                </div>
                <div>
                  <a-radio-group name="radioGroup" v-model="defaultRole" @change="onRoleDeFault"
                                 :disabled="!checkedDesigner" v-if="checkedRoleList.length>1">
                    <a-radio value="4">设为默认身份</a-radio>
                  </a-radio-group>
                </div>
              </div>

            </div>

          </a-checkbox-group>
          <div style="display: flex;padding-top: 40px;align-items: center;justify-content: center">
            <!--            <div class="toWrite" @click="toSubjectType" style="cursor:pointer;">-->
            <!--              <img src="../assets/to_wirte.png">-->
            <!--              修改主体类型-->
            <!--            </div>-->
            <a-button :disabled="loading" :loading="loading" type="primary" block
                      style="width: 113px;"
                      @click="handleRoleFinish">下一步
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <div class="login-content-bottom"></div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import { postAction, getAction, postQueryAction } from '@/api/manage'
import Captcha from '@/components/tools/captcha.vue'
import WinTitle from '@/components/winTitle/index.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import uploadFile from '@/components/plugins/uploadFile.vue'
import ProvincePicker from '@/views/businessManagement/companyInfo/modules/provincePicker.vue'
import AddressPicker from '@/views/businessManagement/companyInfo/modules/addressPicker.vue'
import region from '@/util/region.json'
import { IdType } from '@/util/constant'
import { refreshUserTokenInfo, setCurBusiness, setCurBusinessId, setLocalStorageItem } from '@/util/utils'

export default {
  name: 'login',
  components: {
    AddressPicker, ProvincePicker, uploadFile,
    JDictSelect,
    WinTitle,
    Captcha
  },
  data() {
    let validatePass = (rule, value, callback) => {
      let reg = /^(?=.*[a-zA-Z])(?=.*\d).+$/
      if (!value) {
        callback(new Error('请输入密码'))
        // 密码（8-20位）
      } else if (value.length < 8 || value.length > 16) {
        callback(new Error('密码长度要求密码长度应至少为 8 个字符，最多不超过 16 个字符'))
      } else if (!reg.test(value)) {
        callback(new Error('密码必须包含字母加数字'))
      } else {
        if (this.form.password !== '') {
          this.$refs.registerPassword.validateField('password')
        }
        callback()
      }
    }
    let validatePassPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    let validatePassPhone1 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入密码'))
        // 密码（8-20位）
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error('请输入8-20位密码'))
      } else {
        if (this.form.password !== '') {
          this.$refs.registerPhone.validateField('password')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error('再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入有效的手机号'))
      } else {
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      let reg = new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    const validateIdentity = (rule, value, callback) => {
      if (value == null) {
        callback(new Error('请选择用户角色'))
      } else {
        callback()
      }
    }


    return {
      disabled: {
        type: Boolean,
        default: false
      },
      isSupplier: false,
      defaultRole: '2',
      subjectTypeVisible: false,
      companyInfoVisible: false,
      personInfoVisible: false,
      residentIdentityVisible: false,
      checkAll: false,
      checkedRoleList: ['2', '4'],
      indeterminate: true,
      plainOptions: [],
      value: 0,
      type: 'login',
      form: {
        mobile: null,
        captcha: null
      },
      companyInfo: {},
      personInfo: {},
      sendCodeSec: 0,
      activeKey: '1',
      timer: null,
      licensePictureList: [],
      registerPasswordRule: {
        username: { required: true, message: '账号不能为空' },
        newPassword: { required: true, validator: validatePass },
        password: { required: true, validator: validatePass2 },
        tradeIdentity: { required: true, message: '请选择用户角色', validator: validateIdentity },
        roleType: { required: true, message: '请选择用户类型' },
        isRead: { required: true, message: '请勾选隐私协议' }
      },
      registerPhoneRule: {
        username: [
          { required: true, message: '请输入账号' },
          { required: true, max: 20, message: '账号最多不超过 20 个字符' }
        ],
        mobile: { required: true, validator: validatePhone },
        captcha: { required: true, message: '请输入短信验证码' },
        newPassword: { required: true, validator: validatePassPhone1, trigger: 'change' },
        pwd: { required: true, validator: validatePassPhone, trigger: 'change' },
        tradeIdentity: { required: true, message: '请选择用户角色', validator: validateIdentity },
        roleType: { required: true, message: '请选择用户类型' },
        isRead: { required: true, message: '请勾选隐私协议' }
      },
      loginPhoneRule: {
        mobile: { required: true, validator: validatePhone },
        captcha: { required: true, message: '请输入短信验证码' }
      },
      loginByUsernameRule: {
        username: { required: true, message: '账号不能为空' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 16, message: '密码长度要求密码长度应至少为 8 个字符，最多不超过 16 个字符' }
        ]
      },
      loading: false,
      resetPhoneRule: {
        phone: { required: true, validator: validatePhone, trigger: 'change' },
        captcha: { required: true, message: '请输入短信验证码' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' }
        ],
        confirmPassword: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' }
        ]
      },
      resetEmailRule: {
        email: { required: true, validator: validateEmail },
        captcha: { required: true, message: '请输入短信验证码' },
        password: [
          { required: true, message: '请输入登录密码' },
          { required: true, min: 8, max: 20, message: '请输入8-20位密码' }
        ]
      },
      userTypList: [
        {
          label: '企业',
          value: 'BUSINESS'
        },
        {
          label: '个人',
          value: 'PERSON'
        }
      ],
      // 0-生产商，1-服务商，2-采购商
      tradeIdentityList: {
        BUSINESS: [
          {
            label: '采购商',
            value: 2,
            desc: '发布采购需求的用户角色'
          },
          {
            label: '生产商/贸易商',
            value: 0,
            desc: '发布需求、接收订单、生产订单的制造、贸易或服务用户角色'

          }
        ],
        PERSON: [
          {
            label: '采购商',
            value: 2,
            desc: '发布采购需求的用户角色'

          }
        ]
      },
      region,
      businessType: null,
      checkedSupplier: false,
      checkedPurchaser: false,
      checkedDesigner: false,
      checkedFacilitator: false
    }
  },
  created() {
    let routerType = this.$route.query.type
    this.type = routerType || 'login'
  },
  methods: {
    ...mapActions(['login']),
    handleChangeIdentity() {
      this.$forceUpdate()
    },
    handleSwitchUserType() {
      // this.tradeIdentityList[1]['disabled'] = this.form.roleType && this.form.roleType == 'PERSON'
      this.form.tradeIdentity = undefined
      this.$forceUpdate()
    },
    handleLoginSendMsg() {
      this.$refs.loginByPhone.validateField('mobile', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.mobile)
        }
      })
    },
    // 重置密码
    handleSendRestMessage() {
      this.$refs.restByPhone.validateField('phone', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.phone)
        }
      })
    },
    /* 显示图形验证码 */
    handleShowCaptcha() {
      this.$refs.registerPhone.validateField('mobile', (res) => {
        if (!res) {
          this.$refs.Captcha.handleOpenModal(this.form.mobile)
        }
      })
    },
    uploadFileChange(rule, value, callback) {
      console.log(this.licensePictureList.length === 0)
      if (this.licensePictureList.length === 0) {
        return callback('请上传营业执照')
      } else {
        return true
      }
    },
    validatePhone(rule, value, callback) {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    },
    handleSendMessage(code) {
      let url = '',
        formData = {}

      // if (!this.form.mobile)
      //   return this.$message.warning({
      //     content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">手机号不能为空</span>,
      //     icon: (h) => <img src="/noPass.png" width="21" height="21" />,
      //   })
      url = '/sms/captcha'
      formData = {
        phone: this.form.mobile || this.form.phone,
        code,
        templateCode: this.type == 'register' ? 'TENCENT_REGISTER' : 'TENCENT_LOGIN'
      }
      // }
      postAction(url, formData).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success({
            content: (h) => (
              <span style="margin-left: 9px; font-weight: 500;color: #262626;"> 验证码已经发送，请查看手机短信</ span>
            ),
            icon: (h) => <img src="/pass.png" width="21" height="21" />
          })
          this.sendCodeSec = 60
          this.Timer = setInterval((_) => {
            this.sendCodeSec--
            if (this.sendCodeSec == 0) {
              clearInterval(this.Timer)
            }
          }, 1000)
        } else {
          this.$message.warning(message) // 失败
        }
      })
    },
    handleChangeTab(key) {
      this.type = key
      this.form = {}
      if (this.type == 'register') {
        this.form.roleType = 'BUSINESS'
      }
      this.activeKey = '1'
      this.Timer && clearInterval(this.Timer)
      this.sendCodeSec = 0
      this.loading = false
    },
    /* 登录 */
    handleLogin(type = false) {
      // 目前没接口模拟登录
      this.login().then((res) => {
        // this.$notification.success({
        //   message: '欢迎',
        //   description: `${timeFix()}，欢迎回来`
        // })
        // 获取用户信息
        this.checkRegister(() => this.handleLoadUserInfo(() => this.handleJumpPage(type)))
      })
    },
    /* 手机号登录 */
    handleLoginByPhone() {
      // 我已阅读并同意
      this.$refs['loginByPhone'].validate((valid) => {
        if (valid) {
          // if (!this.form.isRead) return this.$message.warning({
          //   content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请勾选，我已阅读并同意用户协议和隐私协议</span>,
          //   icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          // })
          this.loading = true
          const { mobile, captcha } = this.form
          postAction('/user/login/loginOrRegister', {
            mobile,
            captcha
          }).then((res) => {
            const { success, data, message } = res
            this.loading = false
            if (success) {
              const { tokenValue, tokenName, loginId } = data
              let Authorization = tokenValue
              localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
              // this.$message.success(message)
              this.handleLogin() // 进入系统
            } else {
              this.$message.warning(message)
            }
          }).catch(err => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    /* 获取用户信息 */
    checkRegister(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      if (!storageInfo) {
        return this.$message.warning('用户ID不能为空')
      }
      const { userId } = JSON.parse(storageInfo)
      postAction('/user/login/checkRegister', { userId }).then((res) => {
        const { success, message, data } = res
        if (success) {
          if (data == null || data == false) {
            this.subjectTypeVisible = true
          } else {
            callback()
          }
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 获取用户信息 */
    handleLoadUserInfo(callback) {
      if (callback) {
        callback()
      }
      // let storageInfo = localStorage.getItem('authInfo')
      // if (!storageInfo) {
      //   return this.$message.warning('用户ID不能为空')
      // }
      // const { userId } = JSON.parse(storageInfo)
      // getAction('/user/info', { userId }).then((res) => {
      //   const { success, message, data } = res
      //   if (success) {
      //     let userInfo = JSON.stringify(data)
      //     localStorage.setItem('userInfo', userInfo)
      //     this.$store.commit('setUserInfo', data)
      //     callback()
      //   } else {
      //     this.$message.warning(message)
      //   }
      // })
    },
    /* 账号密码登录 */
    handleLoginByUsername() {
      this.$refs['loginByUsername'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          // if (!this.form.isRead) return this.$message.warning({
          //   content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">请勾选，我已阅读并同意用户协议和隐私协议</span>,
          //   icon: (h) => <img src="/noPass.png" width="21" height="21" />,
          // })
          this.loading = true
          const { password, username } = this.form
          postAction('/user/login/password', {
            password,
            userNameOrPhone: username
          })
            .then((res) => {
              const { success, data, message } = res
              this.loading = false
              if (success) {
                // this.$message.success('登录成功')
                const { tokenValue, tokenName, loginId } = data
                let Authorization = tokenValue
                setLocalStorageItem('authInfo',{ Authorization, userId: loginId }).then(()=>{
                  this.handleLogin() // 进入系统
                })
              } else {
                this.$message.warning(message)
              }
            })
            .catch((err) => {
              // this.$message.warning('环境正在部署中')
              console.log(err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    /* 跳转页面 */
    handleJumpPage(type) {
      refreshUserTokenInfo(this, () => {
        const { query } = this.$route
        console.log(query)
        if (query.redirect) {
          const redirect = decodeURIComponent(query.redirect)
          setTimeout((_) => this.$router.push(redirect), 1500)
        } else {
          // setTimeout((_) => this.$router.push('/'), 1500)
          type && sessionStorage.setItem('isFirst', '1')
          setTimeout((_) => this.$router.push(type ? '/workSpace' : '/'), 1500)
        }
      })

    },
    /* 手机号注册 */
    handleRegisterByPhone() {
      this.$refs['registerPhone'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          if (!this.form.isRead) return this.$message.warning({
            content: (h) => <span
              style="margin-left: 9px; font-weight: 500;color: #262626;"> 请勾选，我已阅读并同意用户协议和隐私协议</ span>,
            icon: (h) => <img src="/noPass.png" width="21" height="21" />
          })
          this.loading = true
          const { pwd, mobile, captcha, tradeIdentity, roleType, username } = this.form
          postAction('/user/register/phoneAndPassword', {
            captcha,
            mobile,
            pwd,
            tradeIdentity,
            roleType,
            userName: username
          }).then((res) => {
            const { code, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success('注册成功')
              // 手机号登录
              postAction('/user/login/password', {
                password: pwd,
                userName: username
              })
                .then((res) => {
                  const { success, data, message } = res
                  this.loading = false
                  if (success) {
                    // this.$message.success('登录成功')
                    const { tokenValue, loginId } = data
                    let Authorization = tokenValue
                    localStorage.setItem('authInfo', JSON.stringify({ Authorization, userId: loginId }))
                    this.handleLogin(1) // 进入系统
                  } else {
                    this.$message.warning(message)
                  }
                })
            } else {
              this.$message.warning(message)
            }
          }).catch(e => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    /* 邮箱注册 */
    handleRegisterByEmail() {
      this.$refs['registerEmail'].validate((valid) => {
        console.log(valid, this.form)
        if (valid) {
          this.loading = true
          postAction('/juno-users/register/email', {
            ...this.form
          }).then((res) => {
            const { code, data, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success(message)
              setTimeout((_) => this.handleChangeTab('login'), 1500)
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          return false
        }
      })
    },
    /* 切换tab */
    handleSwitchTab() {
      this.form = {}
      if (this.type == 'register') {
        this.form.roleType = 'BUSINESS'
      }
      this.loading = false
      this.Timer && clearInterval(this.Timer)
      this.sendCodeSec = 0
      this.$refs.loginByPhone && this.$refs.loginByPhone.clearValidate()
      this.$refs.loginByUsername && this.$refs.loginByUsername.clearValidate()
      this.$refs.registerPhone && this.$refs.registerPhone.clearValidate()
      this.$refs.registerPassword && this.$refs.registerPassword.clearValidate()
    },
    /* 手机号重置密码 */

    handleResetPasswordByPhone() {
      this.$refs['restByPhone'].validate((valid) => {
        if (valid) {
          if (this.form.password != this.form.confirmPassword) {
            this.$message.error('两次密码输入不对')
            return false
          }
          // this.loading = true
          postAction('/user/pc/user/PassForgetUpdate', {
            ...this.form
          }).then((res) => {
            const { code, message } = res
            this.loading = false
            if (code == '200') {
              this.$message.success('密码修改成功')
              setTimeout((_) => this.handleChangeTab('login'), 1500)
            } else {
              this.$message.warning(message)
            }
          })
        } else {
          return false
        }
      })
    },
    handleRegister() {
      this.subjectTypeVisible = true
    }, onCompanyInfo() {
      this.companyInfoVisible = false
      this.subjectTypeVisible = false
      this.businessType = 'BUSINESS'
      this.residentIdentityVisible = true
      this.checkedRoleList = ['0', '2', '4']
      this.defaultRole = '2'
      this.checkedDesigner = true
      this.checkedSupplier = true
      this.checkedPurchaser = true
      this.checkedFacilitator = false

    }, onPerson() {
      this.personInfoVisible = false
      this.subjectTypeVisible = false
      this.residentIdentityVisible = true
      this.businessType = 'PERSON'
      this.checkedRoleList = ['2', '4']
      this.defaultRole = '2'
      this.checkedDesigner = true
      this.checkedSupplier = false
      this.checkedPurchaser = true
      this.checkedFacilitator = false
    }, onReturnStep() {
      this.companyInfoVisible = false
      this.subjectTypeVisible = false
      this.personInfoVisible = false
      this.residentIdentityVisible = true
      this.loading = false
    },
    handleRoleFinish() {
      if (this.checkedRoleList.length == 0) {
        this.$message.error('入驻身份必须选一个')
        return
      }
      const roleList = []
      let isSupplier = false
      let isService = false
      let setDefaultFlagIsSuccess = false
      this.checkedRoleList.map((item) => {
        const roleItem = {}
        roleItem.roleType = item
        roleItem.defaultFlag = 0
        if (item === this.defaultRole) {
          roleItem.defaultFlag = 1
          setDefaultFlagIsSuccess = true
        }
        if (item === '0') {
          roleItem.roleName = '生产商'
          isSupplier = true
        } else if (item === '1') {
          roleItem.roleName = '服务商'
          isService = true
        } else if (item === '2') {
          roleItem.roleName = '采购商'
        } else if (item === '4') {
          roleItem.roleName = '设计师'
        }
        roleList.push(roleItem)
      })

      if (isSupplier && isService) {
        this.$message.warn('生产商跟服务商只能选择一个')
        return
      }
      if (!setDefaultFlagIsSuccess) {
        this.$message.warn('设为默认身份必须勾选')
        return
      }
      this.isSupplier = isSupplier
      this.roleList = roleList
      this.residentIdentityVisible = false
      if (this.businessType === IdType.BUSINESS) {
        this.companyInfoVisible = true
      } else if (this.businessType === IdType.PERSON) {
        this.personInfoVisible = true
      }

    },
    onResidentIdentity() {
      this.$refs['companyInfo'].validate((valid) => {
        if (valid) {
          if (this.licensePictureList) {
            this.companyInfo.licensePicture = this.licensePictureList[0].url
          }
          this.onFinish()
        }
      })
    },
    onPersonResidentIdentity() {
      this.$refs['personInfo'].validate((valid) => {
        if (valid) {
          this.onFinish()
        }
      })
    },
    toSubjectType() {
      this.residentIdentityVisible = false
      this.subjectTypeVisible = true
      this.companyInfoVisible = false
      this.personInfoVisible = false
    }, onCheckAllChange(e) {
      if (this.businessType == 'PERSON') {
        this.checkedRoleList = e.target.checked ? ['2', '4'] : []
      } else {
        this.checkedRoleList = e.target.checked ? ['0', '1', '2', '4'] : []
      }
      this.indeterminate = false
      this.checkAll = e.target.checked

    }, onRoleChange(checkedRoleList) {
      console.log(checkedRoleList)
      console.log(checkedRoleList.slice().reverse())

      const newList = checkedRoleList.slice().reverse()
      if (newList&&newList[0]=='0'){
        checkedRoleList = checkedRoleList.filter(item => item !== '1')
        this.checkedRoleList = checkedRoleList
      }
      if (newList&&newList[0]=='1'){
        checkedRoleList = checkedRoleList.filter(item => item !== '0')
        this.checkedRoleList = checkedRoleList
      }
      console.log(checkedRoleList)
      const list1 = checkedRoleList.filter(item => item === '0')
      if (list1.length > 0) {
        this.checkedSupplier = true
        if (checkedRoleList.length === 1) {
          this.defaultRole = '0'
        }

      } else {
        if (this.defaultRole === '0') {
          this.defaultRole = null
        }
        this.checkedSupplier = false
      }

      const list2 = checkedRoleList.filter(item => item === '1')
      if (list2.length > 0) {
        this.checkedFacilitator = true
        if (checkedRoleList.length === 1) {
          this.defaultRole = '1'
        }
      } else {
        if (this.defaultRole === '1') {
          this.defaultRole = null
        }
        this.checkedFacilitator = false
      }

      const list3 = checkedRoleList.filter(item => item === '2')
      if (list3.length > 0) {
        this.checkedPurchaser = true
        if (checkedRoleList.length === 1) {
          this.defaultRole = '2'
        }
      } else {
        if (this.defaultRole === '2') {
          this.defaultRole = null
        }
        this.checkedPurchaser = false
      }

      const list4 = checkedRoleList.filter(item => item === '4')
      if (list4.length > 0) {
        this.checkedDesigner = true
        if (checkedRoleList.length === 1) {
          this.defaultRole = '4'
        }
      } else {
        if (this.defaultRole === '4') {
          this.defaultRole = null
        }
        this.checkedDesigner = false
      }

      this.indeterminate = checkedRoleList.length < 4 && checkedRoleList.length > 0
      this.checkAll = checkedRoleList.length === 4
    },
    onRoleDeFault(e) {
      const list = this.checkedRoleList.filter(item => item === e.target.value)
      if (list.length === 0) {
        this.$message.error('此身份未勾选不能设为默认身份')
        e.preventDefault()
      } else {
        this.defaultRole = e.target.value
      }
    },
    onRegionChange(value, selectedOptions) {
      this.companyInfo.provinceName = selectedOptions[0].label
      this.companyInfo.cityName = selectedOptions[1].label
      this.companyInfo.areaName = selectedOptions[2].label

      this.companyInfo.provinceCode = selectedOptions[0].value
      this.companyInfo.cityCode = selectedOptions[1].value
      this.companyInfo.areaCode = selectedOptions[2].value
      this.openMap()
    },
    openMap() {
      const { provinceName, cityName } = this.companyInfo
      let city = ''
      if (this.form.cityName) {
        city =
          provinceName == '北京市' || provinceName == '上海市' || provinceName == '重庆市' || provinceName == '天津市'
            ? provinceName
            : cityName
      }
      this.$refs.MapPickerRef.onOpen(city)
    },
    onFinish() {
      this.loading = true
      let payload = {}
      if (this.businessType === 'PERSON') {
        payload = { ...this.personInfo }
      } else {
        payload = { ...this.companyInfo }
      }

      payload.businessType = this.businessType
      payload.roleList = this.roleList
      delete payload.region
      //this.loading = true
      postAction('/business/save/businessInfo', payload).then((res) => {
        console.log('注册注册注册注册注册注册')
        const { code, message } = res
        if (code == '200') {
          // this.residentIdentityVisible = false
          // this.companyInfoVisible = false
          // this.personInfoVisible = false
          // this.subjectTypeVisible = false
          this.$message.success('注册成功')
          setTimeout((_) => this.handleLoadUserInfo(() => this.handleJumpPage(false)), 1500)
        } else {
          this.loading = false
          this.$message.warning(message)
        }
      }).catch(()=>{
        console.log('aaaaaaaaaaaaaaaa')
        this.loading = false
      })
    },
    onRoleBack() {
      this.subjectTypeVisible = true
      this.residentIdentityVisible = false
    },
    onModalClose() {
      this.subjectTypeVisible = false
      this.residentIdentityVisible = false
      this.personInfoVisible = false
      this.companyInfoVisible = false

    },
    onChoose(position) {
      this.$set(this.form, 'position', position)
    },
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.login-content {
  width: 100%;
  margin: 0;
  height: 100vh;
  display: flex;
  //background: #f6f6f6;
  background: linear-gradient(180deg, #EEF5FC 0%, #EEF5FC 100%);
  //background-image: url('~@/assets/newHomePage/banner-1.png');
  //background-size: 100% 65%;
  //background-position: 0 60px;
  background-repeat: no-repeat;
  justify-content: center;

  //align-items: center;


  .login-content-left {
    width: 446px;
    height: 406px;
    background-image: url('~@/assets/newHomePage/banner-left.png');
    //background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-top: 161px;

    //.logo {
    //  position: absolute;
    //  top: 30px;
    //  left: 30px;
    //  height: 32px;
    //}
    //
    //.login-content-info {
    //  position: absolute;
    //  left: 28%;
    //  top: 33%;
    //
    //  // transform: translateX(-50%);
    //  .p1 {
    //    font-weight: 600;
    //    font-size: 32px;
    //    color: white;
    //  }
    //
    //  .p2 {
    //    font-weight: 600;
    //    font-size: 32px;
    //    color: white;
    //  }
    //
    //  .p3 {
    //    font-size: 16px;
    //    color: white;
    //    margin-top: 30px;
    //  }
    //}
  }

  .login-content-right {
    margin-left: 124px;
    width: 30%;
    position: relative;
    height: auto;
    margin-top: 124px;

    .login-box {
      // margin: 0 auto;
      //width: 380px;
      //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      //background: #fff;
      position: absolute;
      //right: 245px;
      //top: 20px;
      //transform: translate(-50%, -40%);
      // background: #fff;
      // padding: 35px 48px;
      border-radius: 6px;
      z-index: 999;

      &-content {
        padding: 10px 0 0;
      }
    }

    .loginBtn {
      text-align: right;
      // margin: 4px 0;
    }

    .easy-btns-txt {
      text-align: center;
      margin: 30px 0 15px 0;
      color: #999999;
      font-size: 14px;
    }

    .easy-btns {
      // padding: 25px 30px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &-item {
        cursor: pointer;

        &:first-child {
          margin-right: 30px;
        }

        img {
          width: 40px;
          margin-right: 6px;
        }
      }
    }

    .qrBox {
      .flexLayout(@justifyContent: center;);
    }

    .qrCode {
      width: 190px;
    }

    .resetPassword {
      background: #ffffff;
      width: 380px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      ::v-deep .ant-tabs-bar {
        display: none;
      }
    }

    .logo {
      color: #fff;
      font-size: 30px;
      position: absolute;
      left: 30px;
      top: 30px;
    }

    ::v-deep .ant-tabs-nav-scroll {
      display: flex;
      justify-content: flex-start;
    }

    ::v-deep .ant-form-item {
      margin-bottom: 0;


    }

    // ::v-deep .ant-tabs-nav {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #999 !important;

    //   .ant-tabs-tab:not(.ant-tabs-tab-active) {
    //     color: #999 !important;
    //   }
    // }

    // ::v-deep .ant-tabs-bar {
    //   border: none;
    // }

    // ::v-deep .ant-tabs-ink-bar {
    //   background-color: transparent;
    // }

    // ::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #333;
    // }

    // ::v-deep .ant-tabs-nav .ant-tabs-tab-active {
    //   font-size: 28px;
    //   font-weight: 600;
    //   color: #333;
    // }
    ::v-deep .ant-tabs-ink-bar {
      background-color: transparent;
    }

    ::v-deep .ant-input:focus {
      box-shadow: none;
    }

    ::v-deep .ant-input {
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dcdcdc !important;
      border-radius: 4px;
      padding-left: 15px;
      box-sizing: border-box;
      margin-block-start: 20px;
      -webkit-margin-before: 0px;
      margin-block-start: 0px;
    }

    .inp-box {
      width: 320px;
      position: relative;
      height: 40px;

      // margin-top: 20px;
      .inp-txt {
        position: absolute;
        right: 20px;
        top: 50%;
        z-index: 99;
        // bottom: 50%;
        transform: translateY(-50%);
        color: #FF6026;
      }
    }

    .inp-right-txt {
      text-align: right;
      // margin-top: 10px;
      margin-bottom: 20px;
      color: #FF6026;

      span {
        cursor: pointer;
      }
    }

    .login-btn {
      height: 40px !important;
      background: #FF6026 !important;
      border-radius: 4px !important;
      border: 1px solid #FF6026 !important;
      width: 320px !important;
      margin-top: 14px;
    }
  }

  .inp-txt {
    cursor: pointer;
  }

  // .flexLayout(@justifyContent: flex-end;);
  ::v-deep .ant-radio-wrapper {
    margin-right: 0;
  }
}

::v-deep .ant-form-item-required {
  color: #000;
}

::v-deep .ant-radio-group span {
  color: #262626;
}

::v-deep .ant-checkbox-wrapper span {
  color: #999;

  a {
    color: #006490;
  }
}

.register-tabs {
  overflow: visible !important;

  ::v-deep .ant-tabs-bar {
    display: none;
  }

  .tradeIdentityItem {
    ::v-deep .ant-form-item-control {
      width: 245px;
    }
  }

  ::v-deep .has-error .ant-input,
  .has-error .ant-input:hover {
    border-color: #ee4261 !important;
    background-color: #fff;
  }
}

.mb-16 {
  margin-bottom: 16px !important;
}

::v-deep .ant-form-explain {
  margin: 8px 0 0;
  color: #ee4261 !important;
}

::v-deep .ant-tabs-tab {
  margin: 0;
}

.loginTab {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  width: 380px;
  background: #ffffff;

  ::v-deep .ant-tabs-tab {
    padding-left: 12px;
  }
}

.center-logo {
  width: 166.78px;
  height: 48px;
  margin-left: 106px;
  margin-bottom: 32px;
}

::v-deep .ant-tabs-nav {
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

::v-deep .ant-tabs-tab {
  padding: 0px 0 !important;
  //margin-right: 60px;
  font-weight: 600;
  width: 50%;
  padding: 16px 36px !important;
  text-align: center;

  &:not(.ant-tabs-tab-active) {
    color: #999999;
    background: #F5F5F6;
    padding: 16px 36px !important;
    text-align: center;
  }
}

a.link {
  color: #999;

  &:hover {
    color: #006490 !important;
  }
}

.police {
  //text-align: center;
  color: #999999;
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 18px;
  padding-top: 16px;
}

::v-deep .ant-tabs-bar {
  border-color: transparent;
}

.login-header {
  width: 100%;
  height: 60px;
  //background: #fff;
  background: linear-gradient(180deg, #EEF5FC 0%, #EEF5FC 100%);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 15px;

  .logo {
    width: auto;
    height: 40px;
  }
}

::v-deep .ant-form-explain {
  text-align: left;
}

.need-desc {
  .desc {
    color: #333;
    position: relative;

    &::after {
      display: inline-block;
      content: '';
      width: 1px;
      height: 25px;
      background: #dcdcdc;
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  ::v-deep .ant-input {
    padding-left: 80px !important;
  }
}

.block-line {
  width: 1px;
  height: 25px;
  background: #dcdcdc;
  position: absolute;
  right: 102px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 23;
}

.memo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  width: 380px;
  background: #ffffff;
  /* 重置密码 */

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;

  color: #131212;
  padding: 16px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.compact-input {
  width: 100%;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding-left: 80px;
  position: relative;
  box-sizing: border-box;

  .desc {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  ::v-deep .ant-select {
    flex: 1 0 0;
  }

  ::v-deep .ant-select-selection {
    height: 40px;
    border: none;
  }

  ::v-deep .ant-select-selection__rendered {
    height: 40px;
    line-height: 40px;
  }
}

.inline-text {
  width: 100%;
  display: inline-block;
}

.subject {
  position: relative;
  width: 880px;
  min-height: 350px;
  padding: 40px 40px;
  border-radius: 8px;
  background-image: url("../assets/subject_top_bg.png");
  background-repeat: no-repeat;

  ::v-deep [data-v-7589b93f] .ant-checkbox-wrapper span {
    color: #131212;
  }

  ::v-deep .ant-form-item-control {
    width: 320px !important;
  }

  .subject-top {
    flex-direction: row;
    display: flex;
    //justify-content: space-between;
    align-items: flex-start;

  }

  .subject-revert {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FF6026;
  }

  .subject_title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #131212;
    padding-left: 96px;
    padding-right: 177px;
  }

  .subject-card-labe {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #666666;

  }

  .subject-desc {
    display: flex;
    justify-content: center;
    padding-top: 8px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #666666;

  }

  .cards-box {
    display: flex;
    margin-top: 56px;
    justify-content: center;
  }

  .card-box {
    align-items: center;
    text-align: center;
    width: 264px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    // align-items: flex-start;
    padding: 40px 32px 56px;
    //gap: 10px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    border-radius: 20px;

    .card-img {
      width: 80px;
      height: 80px;
      max-width: 100%;
    }

    .card-identity {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      color: #131212;
      padding: 20px 0px;

    }

    .card-desc {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #999999;
      padding-bottom: 24px;

    }
  }
}

::v-deep .ant-modal-content {
  border: 0;
}

::v-deep .ant-modal-body {
  padding: 0px;

}

::v-deep .ant-select-selection--single {
  height: 38px;
  width: 320px;
}

.noPic {
  width: 80px;
  height: 80px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }
}

.card-box-top {
  display: flex;
  align-items: center;
}

.resident-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: #131212;
  padding-right: 8px;

}

.resident-tag {
  background: #E8E9EB;
  border-radius: 3px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #131212;
  padding: 0px 4px;
  width: 44px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.card-box-purchaser,
.card-box-supplier {
  width: 360px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;
  border: 1px solid #EDEFF1;
  border-radius: 8px;
}

.card-box-img {
  width: 48px;
  height: 48px;
}

.card-box-role {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #131212;
}

.card-box-sign {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  padding-top: 2px;
}

.card-box-role-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px dashed #EDEDF0;
  padding-bottom: 12px;
}

.toWrite {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #FF6026;
}

.login-content-bottom {
  position: fixed;
  //background-color: red;
  bottom: 0px;
  background-image: url('~@/assets/newHomePage/banner-bottom.png');
  background-size: 100%;
  width: 100%;
  height: 213px;
  background-repeat: no-repeat;
}

::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: #131212;
}

::v-deep .ant-tabs-nav .ant-tabs-tab:hover {
  color: #131212 !important;
}

::v-deep .ant-input-affix-wrapper {
  width: 320px !important;
}

::v-deep .login-content .login-content-right .inp-box[data-v-7589b93f] {
  width: 320px !important;
}

::v-deep .login-content .login-content-right[data-v-7589b93f] .ant-input {
  width: 320px !important;
}

.login-box-content {
  //padding-left: 40px !important;
  display: grid;
  justify-content: center;
  align-items: center;
  flex-direction: column;


}

.login-content .login-content-right[data-v-7589b93f] .ant-input {
  width: 320px;
}

.line16 {
  width: 320px;
  border: 1px solid #EAEAEA;
}

::v-deep .ant-upload {
  width: 120px;
  height: 80px;
}

.login-tag {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #999999;
  //padding-right: 15px;
  //padding-bottom: 16px;
}

::v-deep [data-v-7589b93f] .ant-form-explain {
  width: 320px !important;
  box-sizing: border-box;
}
::v-deep .ant-input-group-wrapper{
  padding-left: 10px!important;
  padding-right: 10px!important;
}
::v-deep [data-v-988a3608] .ant-upload-list-item{
  width: 120px;
}
::v-deep .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img
{
  object-fit: contain
}
</style>
